import { lazy, Suspense, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  BrowserRouter,
} from "react-router-dom";
import axios, { AxiosError } from "axios";
import {
  Layout,
  message,
  Spin,
} from "antd";
import "./style.css";
import "antd/dist/antd.css";

import * as Interfaces from "./interfaces";

import SidebarMenu from "./components/sidebarMenu";
import ChooseDatabaseModal from "./components/chooseDatabaseModal";
import checkPermission from "./checkPermission";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import Register from "./views/register";
import JoinOrganization from "./views/join-organization";
import MQTT from "./util/mqtt";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import Messages from "./components/Messages";
dayjs.extend(timezone);

const Reporting = lazy(() => import("./views/reporting"));
const WorkRequests = lazy(() => import("./views/work-requests"));
const WorkOrders = lazy(() => import("./views/work-orders"));
const Locations = lazy(() => import("./views/locations"));
const Assets = lazy(() => import("./views/assets"));
const Vendors = lazy(() => import("./views/vendors"));
const Parts = lazy(() => import("./views/parts"));
const Users = lazy(() => import("./views/users"));
const PMSchedules = lazy(() => import("./views/pm-schedules"));
const Home = lazy(() => import("./views/home"));
const Workflows = lazy(() => import("./views/workflows"));
const Settings = lazy(() => import("./views/settings"));
const DataImporter = lazy(() => import("./components/DataImporter"));
const Categories = lazy(() => import("./views/categories"));

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error && error.response && error.response.status == 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      throw error;
    }
  }
);

const App = () => {
  const history = useHistory();
  const [initFinished, setInitFinished] = useState(false);
  const [chooseDatabaseModal, setChooseDatabaseModal] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [userProfile, setUserProfile] = useState<Interfaces.IUserProfile>(null);
  const [dataModels, setDataModels] = useState<Interfaces.IDataModel[]>([]);
  const [dataModelIDMap, setDataModelIDMap] = useState({});
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<Interfaces.IUserRole>(
    "Maintenance Requestor"
  );
  const mqtt = MQTT;

  const init = async (isLogin: boolean) => {
    setLoading(true);
    setInitFinished(false);
    // check for token
    const token = localStorage["token"],
      domain = localStorage["domain"],
      database = localStorage["database"];
    let profile = { rbac: {}, cmms: {} } as Interfaces.IUserProfile;
    if (token) {
      axios.defaults.headers.common.Authorization = token;
      try {
        profile = {
          rbac: (await axios.get(`${process.env.REACT_APP_AUTH_API}/user`))
            .data,
          cmms: {},
        } as Interfaces.IUserProfile;
        setUserProfile(profile);
        let _role: Interfaces.IUserRole = "Maintenance Requestor";
        if (profile)
          _role = profile.rbac.requestorGroups.find((g) =>
            g.group.name.startsWith("Maintenance ")
          )?.group.name as Interfaces.IUserRole;
        setRole(_role);
        if (isLogin)
          history.push(
            _role === "Maintenance Requestor" ? "/work-requests" : "/home"
          );
      } catch (error) {
        message.error("Could not load profile data, try reloading the page");
      }
      if (domain && database) {
        try {
          const _dataModels = (
            await axios.get(
              `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}`
            )
          ).data as Interfaces.IDataModel[];
          let _dataModelIDMap: Interfaces.IDataModelIDMap = {};
          for (let model of _dataModels) {
            _dataModelIDMap[model.name] = model._id;
          }
          // get cmms profile
          const cmmsProfile = (
            await axios.post(
              `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}/${_dataModelIDMap["CMMS/Users"]}/paginate`,
              {
                filter: [
                  {
                    path: "identifier",
                    type: "Text",
                    logic: "is",
                    value: [profile?.rbac.identifier],
                  },
                ],
                timezone: dayjs.tz.guess(),
              }
            )
          ).data;
          if (profile) {
            setUserProfile({
              rbac: profile.rbac,
              cmms: cmmsProfile.result.results[0],
            });
          }
          setDataModelIDMap(_dataModelIDMap);
          setDataModels(_dataModels);
          setChooseDatabaseModal(false);
          setReady(true);
        } catch (error) {
          message.error("Could not load profile data, try reloading the page");
        }
      }
    }
    setInitFinished(true);
    setLoading(false);
  };

  const setDatabase = async (database: Interfaces.IDatabase) => {
    localStorage["domain"] = database.domains[0].domain.name;
    localStorage["database"] = database.identifier;
    window.location.reload();
  };

  useEffect(() => {
    init(false);
    mqtt.connect();
  }, []);

  useEffect(() => {
    if (!localStorage["database"] && userProfile) setChooseDatabaseModal(true);
  }, [userProfile]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/login">
          <Login onLogin={() => init(true)} />
        </Route>
        {/*<Route path="/register">
          <Register onLogin={init} />
        </Route>*/}
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/join-organization">
          <JoinOrganization />
        </Route>

        {userProfile ? (
          <Layout style={{ minHeight: "100vh" }}>
            <Layout.Sider
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
              }}
              theme="dark"
              collapsible
              collapsed={sidebarCollapsed}
              onCollapse={() => setSidebarCollapsed(!sidebarCollapsed)}
            >
              <div className="sider-logo">
                <img src="/logo-america.png" width="auto" alt="logo" />
              </div>
              <div className="sider-parent">
                <SidebarMenu role={role} />
              </div>
            </Layout.Sider>
            <Layout className="site-layout" style={{ marginLeft: 200 }}>
              <Spin spinning={loading} delay={2000}>
                <Suspense fallback={<>Loading...</>}>
                  {ready && userProfile ? (
                    <>
                      <Messages dataModelIDMap={dataModelIDMap} mqtt={mqtt} userProfile={userProfile} />
                      <Route path="/" exact>
                        <Redirect to="/home" />
                      </Route>
                      {checkPermission(role, "data-import") && (
                        <Route path="/data-import">
                          <DataImporter
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "home") && (
                        <Route path="/home">
                          <Home
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "reporting") && (
                        <Route path="/reporting">
                          <Reporting
                            dataModelIDMap={dataModelIDMap}
                            toggleLoader={setLoading}
                            dataModels={dataModels}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "work-orders") && (
                        <Route path="/work-orders">
                          <WorkOrders
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                            mqtt={mqtt}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "pm-schedules") && (
                        <Route path="/pm-schedules">
                          <PMSchedules
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "work-requests") && (
                        <Route path="/work-requests">
                          <WorkRequests
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                            mqtt={mqtt}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "locations") && (
                        <Route path="/locations">
                          <Locations
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "assets") && (
                        <Route path="/assets">
                          <Assets
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "vendors") && (
                        <Route path="/vendors">
                          <Vendors
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "parts") && (
                        <Route path="/parts">
                          <Parts
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "users") && (
                        <Route path="/users">
                          <Users
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "workflows") && (
                        <Route path="/workflows">
                          <Workflows
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}
                      {checkPermission(role, "categories") && (
                        <Route path="/categories">
                          <Categories
                            dataModels={dataModels}
                            dataModelIDMap={dataModelIDMap}
                            userProfile={userProfile}
                            toggleLoader={setLoading}
                          />
                        </Route>
                      )}

                      <Route path="/settings">
                        <Settings
                          dataModels={dataModels}
                          dataModelIDMap={dataModelIDMap}
                          userProfile={userProfile}
                          logout={() => {
                            setUserProfile(null);
                            localStorage.clear();
                          }}
                          changeOrganization={() =>
                            setChooseDatabaseModal(true)
                          }
                          toggleLoader={setLoading}
                        />
                      </Route>
                    </>
                  ) : null}
                  {/*<Layout.Footer style={{ textAlign: 'center' }}>Accumine CMMS ©2021 Created by Accumine</Layout.Footer>*/}
                </Suspense>
              </Spin>
            </Layout>
          </Layout>
        ) : (
          <>{initFinished && <Redirect to={{ pathname: "/login" }} />}</>
        )}
      </Switch>
      {chooseDatabaseModal ? (
        <ChooseDatabaseModal
          cancel={() => setChooseDatabaseModal(false)}
          domains={userProfile?.rbac.domains || []}
          force={true}
          submit={setDatabase}
        />
      ) : null}
    </BrowserRouter>
  );
};

export default App;
