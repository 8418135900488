import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, message, Modal } from "antd";
import axios from "axios";

import * as Interfaces from "../../interfaces";

const ChooseDatabaseModal = ({
  domains,
  force,
  cancel,
  submit,
}: {
  domains: {
    domain: {
      name: string;
    };
  }[];
  force: boolean;
  cancel: any;
  submit: any;
}) => {
  const [databases, setDatabases] = useState<Interfaces.IDatabase[]>();
  const [ready, setReady] = useState(false);

  const fetchDatabases = async () => {
    let _databases: Interfaces.IDatabase[] = [];
    try {
      for (let domain of domains) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_AUTH_API}/${domain.domain.name}/resources/database`
        );
        _databases = _databases.concat(data as Interfaces.IDatabase[]);
      }
      setDatabases(
        _databases.filter(
          (e, i) =>
            _databases.findIndex((a) => a.identifier === e.identifier) === i
        )
      );
      setReady(true);
    } catch (error) {
      message.error("Could not load profile data, try reloading the page");
    }
  };

  useEffect(() => {
    fetchDatabases();
  }, []);

  return (
    <>
      {ready ? (
        <Modal
          title="Choose Organization"
          visible={true}
          onCancel={() => (force ? undefined : cancel())}
          footer={null}
        >
          {(databases || []).map((database) => {
            return (
              <Button
                block
                key={database.identifier}
                type="link"
                onClick={() => submit(database)}
              >
                {database.domains.map((d) => d.domain.name).join(", ")}
              </Button>
            );
          })}
        </Modal>
      ) : null}
    </>
  );
};

ChooseDatabaseModal.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  force: PropTypes.bool,
  cancel: PropTypes.func,
  submit: PropTypes.func,
};

export default ChooseDatabaseModal;
