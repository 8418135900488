import axios from "axios";
import { Form, Input, Button, Layout, Row, Col, Card, message, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const JoinOrganization = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const onNewPassowrd = async (password: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_API}/user`,
        {
          headers: { Authorization: token as string },
        }
      );
      await axios.patch(
        `${process.env.REACT_APP_AUTH_API}/${response.data.domains[0].domain.name}/resource/user/${response.data.identifier}`,
        {
          ...response.data,
          secret: password,
        },
        {
          headers: { Authorization: token as string },
        }
      );
      message.success("Your password has been set");
      history.push("/");
    } catch (error: any) {
      message.error(`Could not save password: ${error.response.data}. Please try again.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    localStorage.clear();
    if (query.get("token")) {
      setToken(query.get("token") as string);
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout>
        <Layout.Content
          style={{ height: "100vh", overflow: "auto", padding: 0 }}
        >
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Col span="8">
              <Card title="Let's setup a password">
                <Form
                  name="save-first-password"
                  layout="vertical"
                  onFinish={(values) => {
                    if (values.password === values.passwordConfirm)
                      onNewPassowrd(values.password);
                  }}
                >
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 10,
                      },
                    ]}
                    help="Use 10 or more characters"
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="passwordConfirm"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Row>
                    <Col span="24">
                      <Form.Item style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

JoinOrganization.propTypes = {};

export default JoinOrganization;
