import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  InboxOutlined,
  ContainerOutlined,
  SettingOutlined,
  PartitionOutlined,
  CalendarOutlined,
  HomeOutlined,
  BranchesOutlined,
  TeamOutlined,
  TagOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import * as Interfaces from "../../interfaces";
import checkPermission from "../../checkPermission";

const SidebarMenu = ({ role }: { role: Interfaces.IUserRole }) => {
  const { pathname } = useLocation();
  return (
    <Menu
      theme="dark"
      selectedKeys={[pathname.split("/")[1]]}
      mode="vertical"
      triggerSubMenuAction="hover"
    >
      <Menu.ItemGroup className="sider-group">
        {checkPermission(role, "home") && (
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <NavLink to="/home">Home</NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        <Menu.Divider style={{ borderColor: "rgba(255,255,255, 0.25)" }} />
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        {checkPermission(role, "work-requests") && (
          <Menu.Item key="work-requests" icon={<InboxOutlined />}>
            <NavLink to="/work-requests">Work Requests</NavLink>
          </Menu.Item>
        )}
        {checkPermission(role, "work-orders") && (
          <Menu.Item key="work-orders" icon={<ContainerOutlined />}>
            <NavLink to="/work-orders">Work Orders</NavLink>
          </Menu.Item>
        )}
        {checkPermission(role, "pm-schedules") && (
          <Menu.Item key="pm-schedules" icon={<CalendarOutlined />}>
            <NavLink to="/pm-schedules">PM Schedules</NavLink>
          </Menu.Item>
        )}
        {checkPermission(role, "workflows") && (
          <Menu.Item key="workflows" icon={<BranchesOutlined />}>
            <NavLink to="/workflows">Workflows</NavLink>
          </Menu.Item>
        )}
        {checkPermission(role, "reporting") && (
          <Menu.Item key="reporting" icon={<DashboardOutlined />}>
            <NavLink to="/reporting">Reporting</NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        <Menu.Divider style={{ borderColor: "rgba(255,255,255, 0.25)" }} />
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        {(checkPermission(role, "parts") ||
          checkPermission(role, "vendors")) && (
          <Menu.SubMenu
            key="inventory-submenu"
            title="Inventory"
            icon={<ToolOutlined />}
          >
            {checkPermission(role, "parts") && (
              <Menu.Item key="parts">
                <NavLink to="/parts">Parts / Inventory</NavLink>
              </Menu.Item>
            )}
            {checkPermission(role, "vendors") && (
              <Menu.Item key="vendors">
                <NavLink to="/vendors">Vendors</NavLink>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(checkPermission(role, "assets") ||
          checkPermission(role, "locations")) && (
          <Menu.SubMenu
            key="assets-submenu"
            title="Assets"
            icon={<PartitionOutlined />}
          >
            {checkPermission(role, "locations") && (
              <Menu.Item key="locations">
                <NavLink to="/locations">Locations</NavLink>
              </Menu.Item>
            )}
            {checkPermission(role, "assets") && (
              <Menu.Item key="assets">
                <NavLink to="/assets">Equipment</NavLink>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {checkPermission(role, "users") && (
          <Menu.Item key="users" icon={<TeamOutlined />}>
            <NavLink to="/users">Users</NavLink>
          </Menu.Item>
        )}

        {checkPermission(role, "categories") && (
          <Menu.Item key="categories" icon={<TagOutlined />}>
            <NavLink to="/categories/work-order-categories">Categories</NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        <Menu.Divider style={{ borderColor: "rgba(255,255,255, 0.25)" }} />
      </Menu.ItemGroup>
      <Menu.ItemGroup className="sider-group">
        <Menu.Item key="settings" icon={<SettingOutlined />}>
          <NavLink to="/settings/profile">Settings</NavLink>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};

SidebarMenu.propTypes = {
  role: PropTypes.string.isRequired,
};

export default SidebarMenu;
