type views =
  | "home"
  | "reporting"
  | "work-requests"
  | "work-orders"
  | "parts"
  | "vendors"
  | "assets"
  | "locations"
  | "users"
  | "pm-schedules"
  | "workflows"
  | "data-import"
  | "notifications"
  | "automation"
  | "categories"
  | "work-order-settings";
const permissions = {
  home: ["Maintenance Administrator", "Maintenance Technician"],
  reporting: ["Maintenance Administrator", "Maintenance Technician"],
  "work-requests": ["Maintenance Administrator", "Maintenance Technician", "Maintenance Requestor"],
  "work-orders": ["Maintenance Administrator", "Maintenance Technician"],
  parts: ["Maintenance Administrator", "Maintenance Technician"],
  vendors: ["Maintenance Administrator", "Maintenance Technician"],
  assets: ["Maintenance Administrator", "Maintenance Technician"],
  locations: ["Maintenance Administrator", "Maintenance Technician"],
  users: ["Maintenance Administrator"],
  "pm-schedules": ["Maintenance Administrator"],
  workflows: ["Maintenance Administrator"],
  "data-import": ["Maintenance Administrator"],
  categories: ["Maintenance Administrator"],
  notifications: ["Maintenance Administrator", "Maintenance Technician"],
  automation: ["Maintenance Administrator"],
  'work-order-settings': ['Maintenance Administrator']
};

const checkPermission = (role: string, view: views) => {
  return permissions[view].includes(role);
};

export default checkPermission;
