import { Badge, List, Typography, message as msg } from "antd";
import dayjs from "dayjs";
import {
  IDataModelIDMap,
  IMessage,
  IPart,
  IUserProfile,
  IWorkOrder,
  IWorkRequest,
} from "../../interfaces";
import calendar from "dayjs/plugin/calendar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import objectIDToTimestamp from "../../util/objectIDToTimestamp";
dayjs.extend(calendar);

const MessageListItem = ({
  message,
  userProfile,
  dataModelIDMap,
  closeModal,
}: {
  message: IMessage;
  userProfile: IUserProfile;
  dataModelIDMap: IDataModelIDMap;
  closeModal: any;
}) => {
  const history = useHistory();
  const read = (message.readBy || [])
    .map((a) => a.userId)
    .includes(userProfile?.cmms._id || "");

  const markRead = async () => {
    try {
      if (message.readBy.constructor !== Array) {
        message.readBy = [
          {
            userId: userProfile?.cmms._id as string,
          },
        ];
      } else {
        message.readBy.push({
          userId: userProfile?.cmms._id as string,
        });
      }
      await axios.patch(
        `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}/${dataModelIDMap["System/Messages"]}/edit-record`,
        message
      );
      msg.success(`Message marked as read`);
    } catch (error) {
      msg.error(`Could not mark message as read: ${error}`);
    }
  };

  return (
    <List.Item
      key={message._id}
      actions={[
        <div key="1">
          {!read && (
            <div onClick={markRead}>
              <Badge
                count={5}
                style={{ color: "transparent", cursor: "pointer" }}
                title="Mark as read"
              />
            </div>
          )}
        </div>,
      ]}
    >
      <List.Item.Meta
        title={
          <>
            {(message.type === "Work Order Updates" ||
              message.type === "New Work Orders") && (
              <Typography.Link
                onClick={() => {
                  closeModal();
                  history.push(`/work-orders/${message.resourceRecord._id}`);
                }}
              >
                W/O # {(message.resourceRecord as IWorkOrder).workOrderNumber} -{" "}
                {(message.resourceRecord as IWorkOrder).title}
              </Typography.Link>
            )}
            {message.type === "New Work Requests" && (
              <Typography.Link
                onClick={() => {
                  closeModal();
                  history.push(`/work-requests/${message.resourceRecord._id}`);
                }}
              >
                Request #{" "}
                {(message.resourceRecord as IWorkRequest).workRequestNumber} -{" "}
                {(message.resourceRecord as IWorkRequest).title}
              </Typography.Link>
            )}
            {message.type === "Low Inventory" && (
              <Typography.Link
                onClick={() => {
                  closeModal();
                  history.push(`/parts/${message.resourceRecord._id}`);
                }}
              >
                Part: {(message.resourceRecord as IPart).name}
              </Typography.Link>
            )}
          </>
        }
        description={
          <>
            <Typography.Title level={5} style={{ display: "block" }}>
              {message.title}
            </Typography.Title>
            <Typography.Text type="secondary" italic>
              {message._id
                ? dayjs(objectIDToTimestamp(message._id)).calendar()
                : ""}{" "}
              by{" "}
              {message.createdBy._id === userProfile?.cmms._id
                ? "you"
                : message.createdBy.name}
            </Typography.Text>
          </>
        }
      />
    </List.Item>
  );
};

export default MessageListItem;
