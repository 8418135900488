import { BellFilled, ReloadOutlined } from "@ant-design/icons";
import {
  Drawer,
  List,
  notification,
  Button,
  Radio,
  Empty,
  Skeleton,
  message,
  Row,
  Col,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import * as Interfaces from "../../interfaces";
import paginate from "../../util/paginate";
import MQTT from "../../util/mqtt";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import MessageListItem from "./MessageListItem";
import makePaginateFilter from "./makePaginateFilter";
import axios from "axios";
dayjs.extend(calendar);

const Messages = ({
  dataModelIDMap,
  mqtt,
  userProfile,
}: {
  dataModelIDMap: Interfaces.IDataModelIDMap;
  mqtt: typeof MQTT;
  userProfile: Interfaces.IUserProfile;
}) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingUnread, setPendingUnread] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginateFilter, setPaginateFilter] =
    useState<Interfaces.IMessageViewFilter>({
      type: "All Messages",
      unread: false,
    });
  const [messages, setMessages] = useState<Interfaces.IMessage[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [paginateConfig, setPaginateConfig] =
    useState<Interfaces.IPaginateConfig>({
      next: "",
      previous: "",
      hasNext: false,
      hasPrevious: false,
      count: 0,
    });

  const [mqttChange, setMqttChange] = useState(false);
  const [requestFetch, setRequestFetch] = useState(true);

  const fetchMoreMessages = async () => {
    setLoading(true);
    try {
      const page = await paginate(
        dataModelIDMap["System/Messages"],
        makePaginateFilter(paginateFilter, userProfile),
        {},
        "next",
        paginateConfig
      );
      setMessages([...messages.concat(page.data)]);

      setPaginateConfig({ ...page.paginateConfig });
    } catch (error) {
      message.error(`Could not fetch messages: ${error}`);
    }
    setLoading(false);
  };

  const fetchUnreadCount = async () => {
    setLoading(true);
    try {
      const page = await paginate(
        dataModelIDMap["System/Messages"],
        makePaginateFilter({ ...paginateFilter, unread: true }, userProfile),
        {},
        "next"
      );
      setUnreadCount(page.paginateConfig.count);
    } catch (error) {
      message.error(`Could not fetch messages: ${error}`);
    }
    setLoading(false);
  };

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const page = await paginate(
        dataModelIDMap["System/Messages"],
        makePaginateFilter(paginateFilter, userProfile),
        {},
        "next"
      );
      setMessages(page.data);
      setPaginateConfig({ ...page.paginateConfig });
    } catch (error) {
      message.error(`Could not fetch messages: ${error}`);
    }
    setLoading(false);
  };

  const markAllAsRead = async () => {
    setLoading(true);
    try {
      let isMore = true;
      while (isMore) {
        const page = await paginate(
          dataModelIDMap["System/Messages"],
          makePaginateFilter({ ...paginateFilter, unread: true }, userProfile),
          {},
          "next"
        );
        isMore = page.paginateConfig.count > 0;
        await Promise.all(
          page.data.map((m: Interfaces.IMessage) => {
            if (m.readBy.constructor !== Array) {
              m.readBy = [
                {
                  userId: userProfile?.cmms._id as string,
                },
              ];
            } else {
              m.readBy.push({
                userId: userProfile?.cmms._id as string,
              });
            }
            return axios.patch(
              `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}/${dataModelIDMap["System/Messages"]}/edit-record`,
              m
            );
          })
        );
      }
      message.success("All unread messages marked as read");
    } catch (error) {
      message.error(`Could not mark all as read: ${error}`);
    }
    setLoading(false);
  };

  const mqttCallback = (
    record: Interfaces.IMessage,
    _opts: any,
    path: string,
    _: string
  ) => {
    if (record.module !== "CMMS") {
      return;
    }

    const operationType = path.split("/")[path.split("/").length - 1];
    if (
      operationType === "create" &&
      !record.readBy
        .map((a) => a.userId)
        .includes(userProfile?.cmms._id as string)
    ) {
      if (modalOpen) {
        setPendingUnread(true);
      } else {
        notification.info({
          onClick: () =>
            history.push(`/work-orders/${record.resourceRecord._id}`),
          message: (
            <>
              {(record.type === "Work Order Updates" ||
                record.type === "New Work Orders") && (
                <div style={{ cursor: "pointer" }}>
                  W/O #{" "}
                  {
                    (record.resourceRecord as Interfaces.IWorkOrder)
                      .workOrderNumber
                  }{" "}
                  - {(record.resourceRecord as Interfaces.IWorkOrder).title}
                </div>
              )}
              {record.type === "New Work Requests" && (
                <div style={{ cursor: "pointer" }}>
                  Request #{" "}
                  {
                    (record.resourceRecord as Interfaces.IWorkRequest)
                      .workRequestNumber
                  }{" "}
                  - {(record.resourceRecord as Interfaces.IWorkRequest).title}
                </div>
              )}
              {record.type === "Low Inventory" && (
                <div style={{ cursor: "pointer" }}>
                  Part {(record.resourceRecord as Interfaces.IPart).name}
                </div>
              )}
            </>
          ),
          description: record.title,
          duration: 15,
        });
        setMqttChange(true);
      }
    } else if (operationType === "update" || !modalOpen) {
      setMqttChange(true);
    }
  };

  useEffect(() => {
    setMqttChange(false);
    setRequestFetch(true);
    setPendingUnread(false);
  }, [mqttChange]);

  useEffect(() => {
    if (requestFetch) {
      setRequestFetch(false);
      fetchMessages();
      fetchUnreadCount();
    }
  }, [requestFetch]);

  useEffect(() => {
    mqtt.router.on(
      `${localStorage["database"]}/${dataModelIDMap["System/Messages"]}/#`,
      mqttCallback
    );
    return () => {
      mqtt.router.removeListener(
        `${localStorage["database"]}/${dataModelIDMap["System/Messages"]}/#`,
        mqttCallback
      );
    };
  }, []);

  return (
    <>
      {!modalOpen && (
        <div className="messages-icon" onClick={() => setModalOpen(true)}>
          <BellFilled
            style={{ fontSize: 16, color: unreadCount ? "#ff4d4f" : "" }}
          />
        </div>
      )}
      <Drawer
        width={600}
        onClose={() => setModalOpen(false)}
        visible={modalOpen}
        title="Messages"
        /*extra={
          <Button
            onClick={() => {
              setModalOpen(false);
              history.push("/settings/notifications");
            }}
          >
            Subscribe...
          </Button>
        }*/
      >
        <Spin spinning={loading}>
          <Row>
            <Col span="14">
              <Radio.Group
                onChange={(e) => {
                  paginateFilter.unread = e.target.value === "Unread";
                  setPaginateFilter({ ...paginateFilter });
                  setRequestFetch(true);
                }}
                value={!paginateFilter.unread ? "All" : "Unread"}
                buttonStyle="solid"
              >
                <Radio.Button value="All">All</Radio.Button>
                <Radio.Button value="Unread">
                  Unread {unreadCount > 0 ? <>({unreadCount})</> : ""}
                </Radio.Button>
              </Radio.Group>
              {paginateFilter.unread && unreadCount > 0 && (
                <Button type="link" onClick={markAllAsRead}>
                  Mark all as read
                </Button>
              )}
            </Col>
            <Col span="10" style={{ textAlign: "right" }}>
              <Select
                style={{ width: "200px" }}
                value={paginateFilter.type}
                onSelect={(value) => {
                  paginateFilter.type = value;
                  setPaginateFilter({ ...paginateFilter });
                  setRequestFetch(true);
                }}
                options={[
                  {
                    value: "All Messages",
                  },
                  {
                    value: "New Work Requests",
                  },
                  {
                    value: "New Work Orders",
                  },
                  {
                    value: "Work Order Updates",
                  },
                  {
                    value: "Low Inventory",
                  },
                ]}
                bordered={false}
              />
            </Col>
          </Row>

          <div
            id="messages-list"
            style={{
              height: window.innerHeight * 0.8,
              overflow: "auto",
              backgroundColor: "#fff",
            }}
          >
            {messages.length === 0 &&
            !paginateConfig.hasPrevious &&
            !paginateConfig.hasNext ? (
              <Empty
                style={{ paddingTop: 50 }}
                description="No messages!"
              ></Empty>
            ) : (
              <InfiniteScroll
                dataLength={messages.length}
                next={fetchMoreMessages}
                hasMore={paginateConfig.hasNext}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage=""
                scrollableTarget="messages-list"
              >
                <List
                  bordered
                  rowKey="_id"
                  dataSource={messages}
                  header={
                    pendingUnread && (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          shape="round"
                          type="primary"
                          icon={<ReloadOutlined />}
                          onClick={() => {
                            setPendingUnread(false);
                            setPaginateFilter({
                              type: "All Messages",
                              unread: true,
                            });
                            setRequestFetch(true);
                          }}
                        >
                          View new Messages
                        </Button>
                      </div>
                    )
                  }
                  renderItem={(message) => (
                    <MessageListItem
                      dataModelIDMap={dataModelIDMap}
                      message={message}
                      userProfile={userProfile}
                      closeModal={() => setModalOpen(false)}
                    />
                  )}
                />
              </InfiniteScroll>
            )}
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default Messages;
