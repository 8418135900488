import axios, { AxiosResponse } from "axios";
import { IPaginateConfig, IPaginateSortConfig } from "../interfaces";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

export const paginateAll = async (
  dataModelId: string,
  filter: any,
  sort: any
) => {
  let data: any[] = [],
    next = null,
    previous = null,
    hasNext = true;

  while (hasNext) {
    try {
      const response: AxiosResponse = await axios.post(
        `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}/${dataModelId}/paginate`,
        {
          filter,
          sort,
          next,
          previous,
          limit: 300,
          timezone: dayjs.tz.guess(),
        }
      );
      data = data.concat(response.data.result.results);
      hasNext = response.data.result.hasNext;
      next = response.data.result.next;
    } catch (error) {
      hasNext = false;
      throw error;
    }
  }
  return data;
};

const paginate = async (
  dataModelId: string,
  filter: any,
  sort: IPaginateSortConfig,
  direction: "next" | "previous",
  paginateConfig?: IPaginateConfig,
  limit?: number
): Promise<{
  data: any[];
  paginateConfig: IPaginateConfig;
}> => {
  try {
    if (limit !== undefined && (limit < 1 || limit > 100)) {
      throw Error("limit must be between 1 and 100.");
    }
    const body: any = {
      filter,
      sort,
      limit: limit || 100,
      timezone: dayjs.tz.guess(),
    };
    if (direction === "next" && paginateConfig) {
      body.next = paginateConfig.next;
    }
    if (direction === "previous" && paginateConfig) {
      body.previous = paginateConfig.previous;
    }
    const response: AxiosResponse = await axios.post(
      `${process.env.REACT_APP_DATA_MODEL_API}/${localStorage["database"]}/${dataModelId}/paginate`,
      body
    );
    return {
      data: response.data.result.results,
      paginateConfig: {
        next: response.data.result.next,
        previous: response.data.result.previous,
        hasNext: response.data.result.hasNext,
        hasPrevious: response.data.result.hasPrevious,
        count: response.data.count,
      },
    };
  } catch (error) {
    throw error;
  }
};

export default paginate;
