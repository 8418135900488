import { IMessageViewFilter, IUserProfile } from "../../interfaces";
const makePaginateFilter = (
  paginateFilter: IMessageViewFilter,
  userProfile: IUserProfile
) => {
  let filter = [
    {
      path: "module",
      logic: "is",
      value: ["CMMS"],
      type: "Text",
    },
  ];
  if (paginateFilter.type !== "All Messages") {
    filter.push({
      path: "type",
      logic: "is",
      value: [paginateFilter.type],
      type: "Text",
    });
  }
  if (paginateFilter.unread) {
    filter.push({
      path: "readBy.userId",
      logic: "isNot",
      value: [userProfile?.cmms._id as string],
      type: "Text",
    });
  }
  return filter;
};

export default makePaginateFilter;
