import PropTypes from "prop-types";
import axios from "axios";
import { Form, Input, Button, Layout, Row, Col, Image, Spin, message, Typography } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";

const Login = ({ onLogin }: { onLogin: any }) => {
  const [loading, setLoading] = useState(false);
  
  const onFinish = async (login: any) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_AUTH_API}/login`,
        {
          type: "user",
          identifier: login.username.trim().toLowerCase(),
          secret: login.password,
        }
      );
      localStorage["token"] = data.token;
      onLogin();
    } catch (error: any) {
      if (error.response && error.response.status === 401){
        message.error('Invalid email / password combination');
      } else {
        message.error(`Could not login: ${error}. Please try again.`);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout>
        <Layout.Content
          style={{ height: "100vh", overflow: "auto", padding: 0, background: '#fff' }}
        >
          <Row align="middle" style={{ minHeight: "100vh" }}>
            <Col
              span="8"
              style={{
                textAlign: "center",
                padding: 20,
                borderRight: "1px solid lightgrey",
              }}
            >
              <Image
                style={{ textAlign: "center" }}
                src="/logo-blue.png"
                preview={false}
              />
              <Typography.Title style={{ textAlign: "center" }} level={3}>
                Accumine CMMS
              </Typography.Title>
              <Typography.Title
                style={{ textAlign: "center" }}
                level={5}
                type="secondary"
              >
                Sign In
              </Typography.Title>
              <Form
                  name="login"
                  layout="vertical"
                  onFinish={(values) => onFinish(values)}
                >
                  <Form.Item
                    label="Email Address"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Row>
                    <Col span="12">
                      <Form.Item style={{ textAlign: "left" }}>
                        <Link to="/reset-password">
                          <Button type="link">Forgot your password?</Button>
                        </Link>
                      </Form.Item>
                    </Col>
                    <Col span="12">
                      <Form.Item style={{ textAlign: "right" }}>
                        <Button type="primary" htmlType="submit">
                          Sign In {" "}<ArrowRightOutlined/>
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
            </Col>
            <Col span="16" style={{ textAlign: "center" }}>
              <Image
                src="/login-page-illustration.gif"
                width={750}
                preview={false}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;
